export default class MapService {
  constructor() {

  }


  lookup(address) {
    return new Promise((resolve, reject) => {
      const geocoder = new mapkit.Geocoder({
        language: 'de'
      })

      geocoder.lookup(address, (error, data) => {
        if (error) {
          reject(null)

          return
        }

        if (data.results.length === 0) {
          reject(null)

          return
        }

        const coordinate = data.results[0].coordinate

        resolve({
          latitude: coordinate.latitude,
          longitude: coordinate.longitude
        })
      }, {
        limitToCountries: 'DE'
      })
    })
  }

  search(query) {
    return new Promise((resolve, reject) => {
      const search = new mapkit.Search({
        language: "de",
        region: new mapkit.CoordinateRegion(new mapkit.Coordinate(51.6246571, 9.8255757), new mapkit.CoordinateSpan(10, 10))
      });

      search.search(query, (error, data) => {
        if (error) {
          reject(null)

          return
        }

        if (data.places.length === 0) {
          reject(null)

          return
        }

        const region = data.places[0].region

        resolve({
          latitude: region.center.latitude,
          longitude: region.center.longitude,
          latitudeDelta: region.span.latitudeDelta,
          longitudeDelta: region.span.longitudeDelta,
        })
      })
    })
  }
}