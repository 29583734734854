<template>
  <div>
    <template v-if="spots.length === 0">
      <div class="grey--text text-center ma-10">
        {{ $t('thereAreCurrentlyNoSpotsAvailable') }}
      </div>
    </template>
    <v-row>
      <v-col cols="12"
             lg="4"
             md="6"
             v-for="spot in spots">
        <v-card target="_blank"
                v-bind:rounded="$vuetify.breakpoint.mdAndDown ? null : 'lg'"
                v-bind:tile="$vuetify.breakpoint.mdAndDown"
                v-bind:href="spot.link">
          <v-toolbar dark
                     flat>
            <v-toolbar-title>{{ spot.title }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text style="background-color: #d8d8d8;">
            <v-img v-bind:src="attachmentUrl(spot.imageId)"
                   v-if="spot.imageId != null" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters([
        'attachmentUrl'
      ])
    },
    methods: {
    },
    name: 'SpotListCard',
    props: {
      spots: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "thereAreCurrentlyNoSpotsAvailable": "Momentan sind leider keine Spots verfügbar."
    },
    "en": {
      "thereAreCurrentlyNoSpotsAvailable": "There are currently no spots available."
    }
  }
</i18n>