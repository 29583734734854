<template>
  <div>
    <top-header v-bind:title="$t('ourPartners')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-if="hasRole('FoodTruckFinderAdministrator')"
                     v-bind:label="$t('new')"
                     v-bind:to="{ name: 'administration-food-truck-add', query: { partner: true} }" />
                     <header-button icon="mdi-download"
                     right
                     slot="menu"
                     v-if="hasRole('FoodTruckFinderAdministrator')"
                     v-bind:label="$t('export')"
                     v-on:click="exportFoodTrucks" />
    </top-header>
    <v-container>
      <food-truck-administration-list-card v-bind:title="$t('ourPartners')"
                                           v-bind:foodTrucks="partners" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'
  import FoodTruckAdministrationListCard from '@/components/FoodTruckAdministrationListCard'

  export default {
    components: {
      FoodTruckAdministrationListCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      partners() {
        return this.filteredFoodTrucks.filter(f => !!f.partner)
      },
      ...mapGetters({
        filteredFoodTrucks: 'filteredFoodTrucks',
        hasRole: 'auth/hasRole'
      })
    },
    async created() {
      await this.loadFoodTrucks()
    },
    methods: {
      exportFoodTrucks() {
        const sortedPartnersById = this.partners.sort((a, b) => a.id - b.id)

        let csv = "Nummer;Name;Beschreibung;Special Facts;Adresse;PLZ;Stadt;Land;Region;Kategorie-Nr.;Kategorie;Weitere Kategorien;Webseite;E-Mail-Adresse;Telefon;Instagram;Facebook;Kooperationsinfo\n"

        csv += sortedPartnersById.map(f => {
          console.log(f.regionGroup)
          const descriptionWithoutLineBreaks = f.description.replace(/\n/g, ' ')

          return `"${f.id ?? ''}";"${f.title ?? ''}";"${descriptionWithoutLineBreaks}";"${f.specialties ?? ''}";"${f.streetAddress ?? ''}";"${f.postalCode ?? ''}";"${f.city ?? ''}";"${f.country ?? ''}";"${f.regionGroup}";"${f.categoryId ?? ''}";"${f.category?.title ?? ''}";"${f.categories ?? ''}";"${f.web ?? ''}";"${f.email ?? ''}";"${f.phone ?? ''}";"${f.instagram ?? ''}";"${f.facebook ?? ''}";"${f.shop ?? ''}"`
        }).join('\n')


        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv).replaceAll('#', '%23'))
        element.setAttribute('download', 'partner.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
      },
      ...mapActions([
        'loadFoodTrucks'
      ])
    },
    name: 'Partners'
  }
</script>

<i18n>
  {
    "de": {
      "ourPartners": "Unsere Partner",
      "new": "Neu"
    },
    "en": {
      "ourPartners": "Our Partners",
      "new": "New"
    }
  }
</i18n>