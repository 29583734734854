<template>
  <div>
    <v-container v-bind:class="{ 'pa-0': !this.$vuetify.breakpoint.mdAndUp }">
      <food-truck-dialog ref="foodTruckDialog"
                        v-bind:food-truck="selectedFoodTruck"
                        v-model="foodTruckDialog" />

      <food-truck-list-card v-bind:food-trucks="partners"
                            v-on:food-truck-selected="showFoodTruckDialog($event)" />
    </v-container>

    <v-btn bottom
           class="mb-4 mr-4"
           dark
           exact
           fab
           fixed
           right
           v-bind:to="{ name: 'home' }">
      <v-icon>mdi-map</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import FoodTruckDialog from '@/components/FoodTruckDialog'
  import FoodTruckListCard from '@/components/FoodTruckListCard'

  export default {
    components: {
      FoodTruckDialog,
      FoodTruckListCard
    },
    computed: {
      partners() {
        return this.foodTrucks.filter(f => !!f.partner)
      },
      selectedFoodTruck() {
        if (this.foodTruckId == null) {
          return {}
        }

        return this.foodTruck(this.foodTruckId)
      },
      ...mapGetters([
        'foodTruck',
        'foodTrucks'
      ])
    },
    async created() {
      await this.loadCategories()
      await this.loadFoodTrucks()
    },
    data: () => ({
      foodTruckDialog: false,
      foodTruckId: null
    }),
    methods: {
      showFoodTruckDialog(id) {
        this.foodTruckId = id

        this.$refs.foodTruckDialog.open()
      },
      ...mapActions([
        'loadCategories',
        'loadFoodTrucks'
      ])
    },
    name: 'PartnerList'
  }
</script>