import Vue from 'vue'
import VueRouter from 'vue-router'

import { createAuthRouterGuard } from 'kayleen-vue-auth0-client'

import commonRoutes from './common-routes'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  ...commonRoutes
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

router.beforeEach(createAuthRouterGuard(store))

export default router