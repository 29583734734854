<template>
  <div>
    <v-dialog v-bind:fullscreen="$vuetify.breakpoint.mdAndDown" scrollable
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="600">
      <v-card>
        <v-card-text class="pa-4">
          <h1 class="text-h4 black--text text-center mb-6">Unsere neue App steht bereit!</h1>
          <p class="text-center mb-6">Eine neue Version unserer App ist jetzt verfügbar und bietet zahlreiche Verbesserungen und neue Funktionen. Laden Sie die neueste Version jetzt herunter, um die beste Nutzererfahrung zu genießen.<br><br>

Klicken Sie auf die untenstehenden Links, um die aktualisierte App aus Ihrem bevorzugten Store zu installieren.</p>

                <v-btn block depressed
                     class="mb-4"
                     color="black"
                     target="_blank"
                     dark
                     href="https://apps.apple.com/de/app/food-trucks-united/id6499262774">
                <v-icon left>mdi-apple</v-icon>
                App Store
              </v-btn>

              <v-btn block depressed
                     class="mb-4"
                     color="black"
                     target="_blank"
                     dark
                     href="https://play.google.com/store/apps/details?id=branded.de.foodtrucksunited&hl=de&gl=US">
                <v-icon left>mdi-google-play</v-icon>
                Google Play
              </v-btn>
              <v-btn block text
                     class="mb-4"
                     color="black"
                     target="_blank"
                     @click="$emit('input', false)">
                Schließen
              </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {

      ...mapGetters([

      ])
    },
    created() {
    },
    data: () => ({

    }),
    methods: {

      async open() {
        if (this.selectedDate != null) {
          this.selectedWeek = moment(this.selectedDate).isoWeek()
        }

        this.$emit('input', true)
      }
    },
    name: 'FoodTruckDialog',
    props: {

      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {

    },
    "en": {

    }
  }
</i18n>