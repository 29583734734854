import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isAuthenticated)?_c(VMenu,{attrs:{"offset-y":"","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":_vm.$vuetify.breakpoint.smAndDown,"text":_vm.$vuetify.breakpoint.mdAndUp}},on),[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("mdi-account")]),_c('span',{staticClass:"hidden-sm-and-down text-truncate"},[_vm._v(_vm._s(_vm.displayName))])],1)]}}],null,false,1893322137)},[_c(VList,{staticClass:"py-0"},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,[_c('img',{attrs:{"src":_vm.picture}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.displayName))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.email))])],1)],1),_c(VDivider),_vm._t("default"),_c(VListItem,{on:{"click":function($event){return _vm.$emit('sign-out')}}},[_c(VListItemAvatar),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"deep-orange--text"},[_vm._v(_vm._s(_vm.$t('signOut')))])],1)],1)],2)],1):(!_vm.hideSignIn)?_c(VBtn,{attrs:{"icon":_vm.$vuetify.breakpoint.smAndDown,"text":_vm.$vuetify.breakpoint.mdAndUp},on:{"click":function($event){return _vm.$emit('sign-in')}}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("mdi-login")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.$t('signIn')))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }