import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTextField,{attrs:{"flat":"","hide-details":"auto","prepend-icon":"mdi-magnify","solo":"","label":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('categories')))])],1),_c(VList,[_vm._l((_vm.filteredCategories),function(category,index){return [_c(VListItem,{key:((category.id) + "-list_item"),attrs:{"exact":"","to":{ name: 'administration-category', params: { id: category.id } }}},[_c(VListItemAvatar,{attrs:{"color":category.color}},[_c(VIcon,{attrs:{"color":"white","dark":""}},[_vm._v(" "+_vm._s(("mdi-" + (category.icon)))+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(category.title))])],1)],1),(index !== _vm.filteredCategories.length - 1)?_c(VDivider,{key:((category.id) + "-divider"),attrs:{"inset":""}}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }