import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('details')))])],1),_c(VForm,{ref:"form"},[_c(VCardText,[_c(VTextField,{staticClass:"mb-6",attrs:{"filled":"","hide-details":"auto","label":_vm.$t('title'),"rules":[ _vm.rules.required ]},model:{value:(_vm.category.title),callback:function ($$v) {_vm.$set(_vm.category, "title", $$v)},expression:"category.title"}}),_c(VAutocomplete,{staticClass:"mb-6",attrs:{"filled":"","hide-details":"auto","items":_vm.foodIcons,"label":_vm.$t('icon')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(("mdi-" + item)))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item))])],1)]}}]),model:{value:(_vm.category.icon),callback:function ($$v) {_vm.$set(_vm.category, "icon", $$v)},expression:"category.icon"}}),_c('color-field',{attrs:{"filled":"","hide-details":"auto","label":_vm.$t('color'),"rules":[ _vm.rules.required ]},model:{value:(_vm.category.color),callback:function ($$v) {_vm.$set(_vm.category, "color", $$v)},expression:"category.color"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }