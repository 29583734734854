<template>
  <div>
    <v-card>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form">
        <v-card-text>
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('title')"
                        v-bind:rules="[ rules.required ]"
                        v-model="spot.title" />

          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('description')"
                        v-bind:rules="[ rules.required ]"
                        v-model="spot.description" />

          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('link')"
                        v-model="spot.link" />

          <v-checkbox hide-details="auto"
                      v-bind:label="$t('sendNotification')"
                      v-model="sendNotification"
                      class="mb-6" />

          <v-textarea v-if="sendNotification"
                      class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('notificationText')"
                        v-model="spot.notificationText" />


          <v-row>
            <v-col cols="12"
                   md="6">
              <attachment-field accept="image/*"
                                class="mb-6"
                                show-image
                                v-bind:label="$t('image')"
                                v-bind:max-size="10240"
                                v-model="spot.imageId" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  import AttachmentField from '@/components/common/AttachmentField'

  export default {
    components: {
      AttachmentField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    data: () => ({
      sendNotification: false
    }),
    name: 'SpotDetailsCard',
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    props: {
      spot: Object
    },
    watch: {
      sendNotification(value) {
        if (value) {
          this.spot.notificationText = `🔥🔥 ${this.spot.title}: ${this.spot.description}`
        } else if (!value) {
          this.spot.notificationText = null
        }
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "description": "Beschreibung",
      "details": "Details",
      "image": "Bild",
      "link": "Link",
      "title": "Titel",
      "notificationText": "Benachrichtigungstext",
      "sendNotification": "Benachrichtigung senden"
    },
    "en": {
      "description": "Description",
      "details": "Details",
      "image": "Image",
      "link": "Link",
      "title": "Title",
      "notificationText": "Notification text",
      "sendNotification": "Send notification"
    }
  }
</i18n>