import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"offset-y":"","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":_vm.$vuetify.breakpoint.smAndDown,"text":_vm.$vuetify.breakpoint.mdAndUp}},on),[_c(VIcon,{attrs:{"color":_vm.color}},[_vm._v("mdi-translate")])],1)]}}])},[_c(VList,{staticClass:"py-0"},_vm._l((_vm.locales),function(locale){return _c(VListItem,{key:locale.id,attrs:{"two-line":""},on:{"click":function($event){return _vm.$emit('set-locale', { localeId: locale.id })}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(locale.nativeTitle))]),_c(VListItemSubtitle,[_vm._v(_vm._s(locale.title))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }