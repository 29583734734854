<template>
  <div>
    <v-dialog v-bind:fullscreen="$vuetify.breakpoint.mdAndDown"
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="600">
      <v-card>
        <v-form ref="form">
          <v-toolbar color="secondary"
                     dark
                     flat>
            <v-toolbar-title>{{ $t('addSlot') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark
                   icon
                   v-on:click="$emit('input', false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-4">
            <v-select filled
                      hide-details="auto"
                      v-bind:items="daysOfWeekItems"
                      v-bind:label="$t('dayOfWeek')"
                      v-bind:rules="[ rules.required ]"
                      v-model="timeSlot.dayOfWeek" />
            <v-row>
              <v-col>
                <v-checkbox class="mb-6"
                            color="accent"
                            hide-details="auto"
                            v-bind:label="$t('evenWeeks')"
                            v-model="timeSlot.evenWeeks"/>
              </v-col>
              <v-col>
                <v-checkbox class="mb-6"
                            color="accent"
                            hide-details="auto"
                            v-bind:label="$t('oddWeeks')"
                            v-model="timeSlot.oddWeeks"/>
              </v-col>
            </v-row>
            <v-autocomplete class="mb-6"
                            filled
                            hide-details="auto"
                            v-bind:items="times"
                            v-bind:label="$t('from')"
                            v-bind:rules="[ rules.required ]"
                            v-model="timeSlot.from" />
            <v-autocomplete filled
                            hide-details="auto"
                            v-bind:items="times"
                            v-bind:label="$t('to')"
                            v-bind:rules="[ rules.required ]"
                            v-model="timeSlot.to" />
          </v-card-text>
          <v-divider />
          <v-subheader class="font-weight-bold">{{ $t('address') }}</v-subheader>
          <v-card-text>
            <v-text-field class="mb-6"
                          filled
                          hide-details="auto"
                          v-bind:label="$t('comment')"
                          v-model="timeSlot.comment" />

            <v-text-field class="mb-6"
                          filled
                          hide-details="auto"
                          v-bind:label="$t('streetAddress')"
                          v-bind:rules="[ rules.required ]"
                          v-model="timeSlot.streetAddress" />

            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field filled
                              hide-details="auto"
                              v-bind:label="$t('postalCode')"
                              v-bind:rules="[ rules.required ]"
                              v-model="timeSlot.postalCode" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field filled
                              hide-details="auto"
                              v-bind:label="$t('city')"
                              v-bind:rules="[ rules.required ]"
                              v-model="timeSlot.city" />
              </v-col>
              </v-row>
              <v-row>
              <v-col>
                <v-text-field filled
                              hide-details="auto"
                              v-bind:label="$t('country')"
                              v-model="timeSlot.country" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="secondary"
                   text
                   v-on:click="cancel">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="accent"
                   text
                   v-on:click="confirm">
              {{ $t('add') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      daysOfWeekItems() {
        return this.daysOfWeek.map(w => ({
          text: this.$t(w.label),
          value: w.value
        }))
      },
      ...mapGetters([ 'daysOfWeek' ])
    },
    data: () => ({
      resolve: null,
      times: [ '00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'  ],
      timeSlot: {
        evenWeeks: false,
        oddWeeks: false
      }
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(null)
      },
      confirm() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.timeSlot.id = `${this.timeSlot.dayOfWeek}-${this.timeSlot.evenWeeks}-${this.timeSlot.oddWeeks}-${this.timeSlot.from}-${this.timeSlot.to}`

        this.$emit('input', false)
        this.resolve(Object.assign({}, this.timeSlot))
      },
      async open(timeSlot, resolve) {
        if (timeSlot != null) {
          this.timeSlot = timeSlot
        }

        this.resolve = resolve

        this.$emit('input', true)
      }
    },
    name: 'FoodTruckHoursDialog',
    props: {
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "address": "Adresse",
      "addSlot": "Slot hinzufügen",
      "cancel": "Abbrechen",
      "city": "Ort",
      "comment": "Bemerkung",
      "dayOfWeek": "Wochentag",
      "evenWeeks": "Gerade Wochen",
      "from": "Von",
      "oddWeeks": "Ungerade Wochen",
      "postalCode": "PLZ",
      "streetAddress": "Adresse",
      "to": "Bis",
      "country": "Land"
    },
    "en": {
      "add": "Add",
      "address": "Adresse",
      "addSlot": "Add slot",
      "cancel": "Cancel",
      "city": "City",
      "comment": "Comment",
      "dayOfWeek": "Day of week",
      "evenWeeks": "Even weeks",
      "from": "From",
      "oddWeeks": "Odd weeks",
      "postalCode": "Postal code",
      "streetAddress": "Street address",
      "to": "To",
      "country": "Country"
    }
  }
</i18n>