<template>
  <div v-if="currentSpot != null">
    <confirm-dialog ref="confirmDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisSpotIrrevocably')"
                    v-bind:title="$t('deleteSpot')"
                    v-model="confirmDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="currentSpot.title">
      <header-button icon="mdi-chevron-left"
                     left
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'administration-spots' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateCurrentSpot" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item v-on:click="deleteCurrentSpot">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-container>
      <spot-details-card ref="spotDetailsCard"
                         v-bind:spot="currentSpot" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'
  import SpotDetailsCard from '@/components/SpotDetailsCard'

  export default {
    components: {
      ConfirmDialog,
      HeaderButton,
      SpotDetailsCard,
      TopHeader
    },
    computed: {
      ...mapGetters([ 'spot' ])
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      confirmDialog: false,
      currentSpot: null,
      id: null,
      validationSnackbar: false
    }),
    methods: {
      async get(id) {
        this.id = id

        await this.loadSpots()

        this.currentSpot = Object.assign({}, this.spot(id))
      },
      async deleteCurrentSpot() {
        await this.$refs.confirmDialog.open(async r => {
          if (!r) {
            return
          }

          this.deleteSpot({ id: this.id })

          this.$router.push({ name: 'administration-spots' })
        })
      },
      async updateCurrentSpot() {
        if (!this.$refs.spotDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.updateSpot({ spot: this.currentSpot })

        this.$router.push({ name: 'administration-spots' })
      },
      ...mapActions([
        'deleteSpot',
        'loadSpots',
        'updateSpot'
      ])
    },
    name: 'Spot'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisSpotIrrevocably": "Sind Sie sicher, dass Sie diesen Spot unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteSpot": "Spot löschen",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern.",
      "save": "Speichern",
      "saved": "Gespeichert"
    },
    "en": {
      "areYouSureYouWantToDeleteThisSpotIrrevocably": "Are you sure you want to delete this spot irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteSpot": "Delete spot",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention.",
      "save": "Save",
      "saved": "Saved"
    }
  }
</i18n>