<template>
  <div>
    <v-card>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon
                  v-bind="attrs"
                  v-on="on">
              <v-icon>mdi-sort</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group color="primary"
                              mandatory
                              v-model="selectedSortingType">
              <v-list-item v-bind:key="sortingType.value"
                          v-bind:value="sortingType.value"
                          v-for="sortingType in sortingTypes">
                <v-list-item-title>{{ sortingType.label }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <template v-slot:extension>
          <v-text-field flat
                        hide-details="auto"
                        prepend-icon="mdi-magnify"
                        solo
                        v-bind:label="$t('search')"
                        v-model="search" />
        </template>
      </v-toolbar>
      <v-list>
        <template v-for="(foodTruck, index) in filteredFoodTrucks">
          <v-list-item exact
                       v-bind:key="`${foodTruck.id}-list_item`"
                       v-bind:to="{ name: 'administration-food-truck', params: { id: foodTruck.id } }">
            <v-list-item-avatar v-bind:color="foodTruck.logoId == null ? 'primary' : null">
              <v-img v-bind:src="attachmentUrl(foodTruck.logoId)"
                     v-if="foodTruck.logoId != null">
              </v-img>
              <v-icon color="white"
                      dark
                      v-else>
                mdi-truck
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ foodTruck.title }} ({{ foodTruck.id }})</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset
                       v-bind:key="`${foodTruck.id}-divider`"
                       v-if="index !== filteredFoodTrucks.length - 1" />
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      filteredFoodTrucks() {
        let filteredFoodTrucks = this.foodTrucks

        if (this.search != null && this.search.length !== 0) {
          filteredFoodTrucks = filteredFoodTrucks.filter(f => f.title.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.selectedSortingType === 'title') {
          return [...filteredFoodTrucks].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
        } else {
          return [...filteredFoodTrucks].sort((a, b) => a.id - b.id)
        }
      },
      sortingTypes() {
        return [
          {
            label: this.$t('id'),
            value: 'id'
          },
          {
            label: this.$t('title'),
            value: 'title'
          }
        ]
      },
      ...mapGetters({
        attachmentUrl: 'attachmentUrl',
        hasRole: 'auth/hasRole'
      })
    },
    data: () => ({
      search: null,
      selectedSortingType: 'title'
    }),
    name: 'FoodTruckAdministrationListCard',
    props: {
      foodTrucks: Array,
      title: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "foodTrucks": "Foodtrucks",
      "id": "Nummer",
      "search": "Suchen…",
      "title": "Titel"
    },
    "en": {
      "foodTrucks": "Food trucks",
      "id": "ID",
      "search": "Search…",
      "title": "Title"
    }
  }
</i18n>