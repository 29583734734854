<template>
  <div v-bind:class="{ 'map-display': true, 'map-display--mobile': $vuetify.breakpoint.smAndDown }"
       ref="map">
  </div>
</template>

<script>
  export default {
    data: () => ({
      map: null,
      mapAnnotations: [],
      location: null
    }),
    methods: {
      deselect() {
        this.map.selectedAnnotation = null
      },
      updateViewBox() {

        
        
        if (this.location == null) {
          return
        }

        //this.setRegion(this.location.latitude, this.location.longitude, this.location.latitudeDelta, this.location.longitudeDelta, true)

        

        const nearestAnnotation = this.annotations.reduce((nearest, a) => {
          const distance = this.calculateDistance(a.latitude, a.longitude)
          
          if (nearest == null || distance < nearest.distance) {
            return { a, distance }
          }

          return nearest
        }, null)

        console.log(nearestAnnotation?.distance)
        
        if (nearestAnnotation?.distance > 50000) {
          this.setRegion(nearestAnnotation.a.latitude, nearestAnnotation.a.longitude, 0.5, 0.5, true)
        } else if (nearestAnnotation != null) {
          this.zoomToFit([{ coordinate: nearestAnnotation.a }, { coordinate: this.location }])
        } else {
          this.setRegion(this.location.latitude, this.location.longitude, this.location.latitudeDelta, this.location.longitudeDelta, true)
        }
      },
      setLocation(latitude, longitude, latitudeDelta, longitudeDelta, animate) {
        this.location = { latitude, longitude, latitudeDelta, longitudeDelta }

        this.updateViewBox()
        this.updateAnnotations()
      },
      setRegion(latitude, longitude, latitudeDelta, longitudeDelta, animate) {
        this.map.setRegionAnimated(new mapkit.CoordinateRegion(new mapkit.Coordinate(latitude, longitude), new mapkit.CoordinateSpan(latitudeDelta, longitudeDelta)), animate)
      },
      updateAnnotations() {
        this.map.removeAnnotations(this.mapAnnotations)

        this.mapAnnotations = []

        if (this.location != null) {
          const annotation = new mapkit.ImageAnnotation(new mapkit.Coordinate(this.location.latitude, this.location.longitude), {
            collisionMode: mapkit.Annotation.CollisionMode.Circle,
            title: 'Dein Standort',
            animates: false,
            size: {
              width: 24,
              height: 24
            },
            url: { 1: '/images/self-96.png' }
          })

          this.mapAnnotations.push(annotation)
        }

        this.annotations.forEach(a => {
          const annotation = new mapkit.ImageAnnotation(new mapkit.Coordinate(a.latitude, a.longitude), {
            collisionMode: mapkit.Annotation.CollisionMode.Circle,
            clusteringIdentifier: a.clusteringIdentifier,
            title: a.title,
            animates: false,
            data: {
              id: a.id
            },
            size: {
              width: 48,
              height: 48
            },
            url: { 1: a.logoUrl ? a.logoUrl : '/images/marker-96.png' },
            anchorOffset: new DOMPoint(0, -24)
          })

          /*annotation.addEventListener('select', event => {
            const id = event?.target?.data?.id

            if (id != null) {
              this.$emit('annotation-selected', { id })
            }
          })*/

          this.mapAnnotations.push(annotation)
        })

        this.map.addAnnotations(this.mapAnnotations)
      },
      calculateDistance(latitude, longitude) {
        if (this.location == null) {
          return null
        }

        const lat1 = this.location.latitude
        const lon1 = this.location.longitude
        const lat2 = latitude
        const lon2 = longitude

        const R = 6371e3; // Earth's radius in meters
        const toRadians = (degrees) => (degrees * Math.PI) / 180; // Convert degrees to radians

        const φ1 = toRadians(lat1);
        const φ2 = toRadians(lat2);
        const Δφ = toRadians(lat2 - lat1);
        const Δλ = toRadians(lon2 - lon1);

        const a =
          Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // Distance in meters
        return distance;
      },
      zoomToFit(annotations) {
        let northLatitude = Math.max(...annotations.map(a => a.coordinate.latitude))
        let southLatitude = Math.min(...annotations.map(a => a.coordinate.latitude))
        let eastLongitude = Math.max(...annotations.map(a => a.coordinate.longitude))
        let westLongitude = Math.min(...annotations.map(a => a.coordinate.longitude))

        // add padding
        const padding = 0.01
        northLatitude += padding
        southLatitude -= padding
        eastLongitude += padding
        westLongitude -= padding

        const boundingRegion = new mapkit.BoundingRegion(northLatitude, eastLongitude, southLatitude, westLongitude)
        const coordinateRegion = boundingRegion.toCoordinateRegion()

        this.map.setRegionAnimated(coordinateRegion)
      }
    },
    mounted() {
      this.map = new mapkit.Map(this.$refs.map, {
        padding: new mapkit.Padding(
          88, 24, 24, 24
        ),
        region: new mapkit.CoordinateRegion(new mapkit.Coordinate(51.6246571, 9.8255757), new mapkit.CoordinateSpan(10, 10)),
        showsCompass: mapkit.FeatureVisibility.Hidden,
        tintColor: this.$vuetify.theme.currentTheme.primary
      })

      this.map.addEventListener('select', event => {
        const annotation = event?.annotation

        if (annotation == null) {
          return
        }

        const id = annotation.data?.id

        if (id != null) {
          this.$emit('annotation-selected', { id })
        } else if (annotation.memberAnnotations != null) {
          this.zoomToFit(annotation.memberAnnotations)
          //this.setRegion(annotation.coordinate.latitude, annotation.coordinate.longitude, 0.5, 0.5)
        }
      })

      this.updateAnnotations()
      this.updateViewBox()
    },
    name: 'MapComponent',
    props: {
      annotations: Array
    },
    watch: {
      annotations(newAnnotations, oldAnnotations) {
        this.updateAnnotations()
        this.updateViewBox()
      }
    }
  }
</script>

<style scoped>
  .map-display {
    height: calc(100vh - 64px);
  }

  .map-display--mobile {
    height: calc(100vh - 56px);
  }
</style>