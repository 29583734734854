import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentFoodTruck != null)?_c('div',[_c('confirm-dialog',{ref:"confirmDialog",attrs:{"confirm-button-color":"deep-orange","cancel-button-label":_vm.$t('cancel'),"confirm-button-label":_vm.$t('delete'),"message":_vm.$t('areYouSureYouWantToDeleteThisFoodTruckIrrevocably'),"title":_vm.$t('deleteFoodTruck')},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}}),_c(VSnackbar,{attrs:{"color":"deep-orange","right":"","top":""},model:{value:(_vm.validationSnackbar),callback:function ($$v) {_vm.validationSnackbar=$$v},expression:"validationSnackbar"}},[_vm._v(" "+_vm._s(_vm.$t('thereAreItemsThatRequireYourAttention'))+" ")]),_c('top-header',{attrs:{"title":_vm.currentFoodTruck.title}},[_c('header-button',{attrs:{"slot":"left-button","icon":"mdi-chevron-left","left":"","label":_vm.$t('back'),"to":{ name: 'administration-food-trucks' }},slot:"left-button"}),_c('header-button',{attrs:{"slot":"right-button","icon":"mdi-check","right":"","label":_vm.$t('save')},on:{"click":_vm.updateCurrentFoodTruck},slot:"right-button"}),(_vm.hasRole('FoodTruckFinderAdministrator'))?_c(VMenu,{attrs:{"slot":"menu"},slot:"menu",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1510716892)},[_c(VList,{staticClass:"py-0"},[_c(VListItem,{on:{"click":_vm.deleteCurrentFoodTruck}},[_c(VListItemTitle,{staticClass:"deep-orange--text"},[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1):_vm._e()],1),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"secondary","depressed":"","large":"","target":"_blank","href":((_vm.settings.apiUrl) + "/foodtruck/" + _vm.id + "/portfolio.pdf")}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-file-pdf-box ")]),_vm._v(" "+_vm._s(_vm.$t('portfolio'))+" ")],1)],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"secondary","depressed":"","large":"","target":"_blank","href":((_vm.settings.apiUrl) + "/foodtruck/" + _vm.id + "/schedule.pdf")}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-file-pdf-box ")]),_vm._v(" "+_vm._s(_vm.$t('schedule'))+" ")],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c('food-truck-details-card',{ref:"foodTruckDetailsCard",staticClass:"mb-8",attrs:{"food-truck":_vm.currentFoodTruck}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c('food-truck-hours-card',{attrs:{"food-truck":_vm.currentFoodTruck}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }