import Categories from '../views/Categories.vue'
import Category from '../views/Category.vue'
import CategoryAdd from '../views/CategoryAdd.vue'
import FoodTruck from '../views/FoodTruck.vue'
import FoodTruckAdd from '../views/FoodTruckAdd.vue'
import FoodTrucks from '../views/FoodTrucks.vue'
import Partners from '../views/Partners.vue'
import Home from '../views/Home.vue'
import Imprint from '../views/legal/Imprint.vue'
import Licences from '../views/legal/Licences.vue'
import PrivacyPolicy from '../views/legal/PrivacyPolicy.vue'
import List from '../views/List.vue'
import PartnerList from '../views/PartnerList.vue'

import SignInCallback from '../views/SignInCallback.vue'
import SignOutCallback from '../views/SignOutCallback.vue'
import Spots from '../views/Spots.vue'
import SpotsOverview from '../views/SpotsOverview.vue'
import Spot from '../views/Spot.vue'
import SpotAdd from '../views/SpotAdd.vue'
import AdministrationNavigation from '../views/navigation/AdministrationNavigation.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'

export default [
  {
    components: {
      default: Home,
      navigation: PrimaryNavigation
    },
    name: 'home',
    path: '/'
  },
  {
    components: {
      default: Imprint,
      navigation: PrimaryNavigation
    },
    name: 'imprint',
    path: '/legal/imprint'
  },
  {
    components: {
      default: Licences,
      navigation: PrimaryNavigation
    },
    name: 'licences',
    path: '/legal/licences'
  },
  {
    components: {
      default: PrivacyPolicy,
      navigation: PrimaryNavigation
    },
    name: 'privacy-policy',
    path: '/legal/privacy-policy'
  },
  {
    components: {
      default: List,
      navigation: PrimaryNavigation
    },
    name: 'list',
    path: '/list'
  },
  {
    components: {
      default: PartnerList,
      navigation: PrimaryNavigation
    },
    name: 'partner-list',
    path: '/partner-list'
  },
  {
    components: {
      default: SpotsOverview,
      navigation: PrimaryNavigation
    },
    name: 'spots',
    path: '/spots'
  },

  {
    components: {
      default: Categories,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('FoodTruckFinderAdministrator'),
      useCollapsedNavigation: true
    },
    name: 'administration-categories',
    path: '/administration/categories'
  },
  {
    components: {
      default: CategoryAdd,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('FoodTruckFinderAdministrator')
    },
    name: 'administration-category-add',
    path: '/administration/categories/add'
  },
  {
    components: {
      default: Category,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('FoodTruckFinderAdministrator')
    },
    name: 'administration-category',
    path: '/administration/categories/:id'
  },
  {
    components: {
      default: FoodTrucks,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('FoodTruckFinderAdministrator') || hasRole('FoodTruckFinderEditor'))
    },
    name: 'administration-food-trucks',
    path: '/administration/food-trucks'
  },
  {
    components: {
      default: FoodTruckAdd,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('FoodTruckFinderAdministrator')
    },
    name: 'administration-food-truck-add',
    path: '/administration/food-trucks/add'
  },
  {
    components: {
      default: FoodTruck,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('FoodTruckFinderAdministrator') || hasRole('FoodTruckFinderEditor'))
    },
    name: 'administration-food-truck',
    path: '/administration/food-trucks/:id'
  },


  {
    components: {
      default: Partners,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('FoodTruckFinderAdministrator')
    },
    name: 'administration-partners',
    path: '/administration/partners'
  },

  {
    components: {
      default: Spots,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('FoodTruckFinderAdministrator'),
      useCollapsedNavigation: true
    },
    name: 'administration-spots',
    path: '/administration/spots'
  },
  {
    components: {
      default: SpotAdd,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('FoodTruckFinderAdministrator')
    },
    name: 'administration-spot-add',
    path: '/administration/spots/add'
  },
  {
    components: {
      default: Spot,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('FoodTruckFinderAdministrator')
    },
    name: 'administration-spot',
    path: '/administration/spots/:id'
  },

  {
    component: SignInCallback,
    name: 'sign-in-callback',
    path: '/sign-in-callback'
  },
  {
    component: SignOutCallback,
    name: 'sign-out-callback',
    path: '/sign-out-callback'
  }
]