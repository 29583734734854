import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{ref:"menu",attrs:{"min-width":"auto","offset-y":"","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"filled":"","hide-details":"auto","readonly":"","label":_vm.label,"rules":_vm.rules,"value":_vm.value}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"append","left":"","color":_vm.value},slot:"append"},[_vm._v(" mdi-circle ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VColorPicker,{attrs:{"dot-size":"25","swatches-max-height":"200","value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }