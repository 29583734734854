<template>
  <v-app>
    <v-navigation-drawer app
                         clipped
                         color="secondary"
                         dark
                         v-bind:mini-variant="this.$vuetify.breakpoint.lgAndUp"
                         v-model="navigationDrawer"
                         v-if="!chromeless">
      <router-view name="navigation" />
    </v-navigation-drawer>

    <v-app-bar app
               clipped-left
               color="secondary"
               dark
               flat
               v-if="!chromeless">
      <v-app-bar-nav-icon v-if="!this.$vuetify.breakpoint.lgAndUp"
                          v-on:click="navigationDrawer = !navigationDrawer" />

      <v-toolbar-title>
        <span class="font-weight-bold">Foodtrucks</span>
        United
      </v-toolbar-title>

      <v-spacer />

      <v-btn color="accent lighten-3"
             v-bind:href="`mailto:${contactEmail}`"
             v-bind:icon="$vuetify.breakpoint.smAndDown"
             v-bind:text="$vuetify.breakpoint.mdAndUp">
        <v-icon>mdi-email</v-icon>
      </v-btn>

      <locale-block v-bind:locales="locales"
                    v-on:set-locale="setLocale" />

      <account-block v-bind:display-name="displayName"
                     v-bind:email="email"
                     v-bind:hide-sign-in="true"
                     v-bind:is-authenticated="isAuthenticated"
                     v-bind:picture="picture"
                     v-on:sign-in="signIn"
                     v-on:sign-out="signOut">
        <template v-if="hasRole('FoodTruckFinderAdministrator') || hasRole('FoodTruckFinderEditor')">
          <v-list-item v-bind:to="{ name: 'administration-food-trucks' }">
            <v-list-item-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('administration') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
        </template>
      </account-block>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import locales from '@/locales.json'
  import settings from '@/settings'

  import AccountBlock from '@/components/common/AccountBlock'
  import LocaleBlock from '@/components/common/LocaleBlock'

  export default {
    components: {
      AccountBlock,
      LocaleBlock
    },
    computed: {
      chromeless() {
        if (this.keepChromeless) {
          return true
        }

        if (!this.$route.query.chromeless) {
          return false
        }

        this.keepChromeless = true

        return this.$route.query.chromeless
      },
      contactEmail() {
        return settings.email
      },
      locales() {
        return locales
      },
      ...mapGetters({
        displayName: 'auth/displayName',
        email: 'auth/email',
        hasRole: 'auth/hasRole',
        isAuthenticated: 'auth/isAuthenticated',
        picture: 'auth/picture'
      })
    },
    created() {
      this.navigationDrawer = true
    },
    data: () => ({
      navigationDrawer: false,
      keepChromeless: false
    }),
    methods: {
      setLocale({ localeId }) {
        this.$root.$i18n.locale = localeId
      },
      ...mapActions({
        signIn: 'auth/signIn',
        signOut: 'auth/signOut'
      })
    },
    name: 'App'
  }
</script>

<i18n>
  {
    "de": {
      "administration": "Administration"
    },
    "en": {
      "administration": "Administration"
    }
  }
</i18n>