<template>
  <div>
    <food-truck-hours-dialog ref="foodTruckHoursDialog"
                             v-model="foodTruckHoursDialog" />

    <v-card>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('hours') }}</v-toolbar-title>
      </v-toolbar>
      <template v-for="dayOfWeekGroup in timeSlotGroups">
        <v-subheader class="font-weight-bold"
                     v-bind:key="`${dayOfWeekGroup.dayOfWeek}-subheader`">
          {{ $t(daysOfWeekLabel(dayOfWeekGroup.dayOfWeek)) }}
        </v-subheader>
        <v-card-text v-bind:key="`${dayOfWeekGroup.dayOfWeek}-card_text`">
          <v-row align="center"
                 v-bind:key="timeSlot.id"
                 v-for="timeSlot in dayOfWeekGroup.timeSlots">
            <v-col>
              <strong>{{ timeSlot.from }}–{{ timeSlot.to }}</strong><br>
              <v-chip class="mr-1"
                      label
                      small
                      v-if="timeSlot.evenWeeks">
                {{ $t('evenWeeks') }}
              </v-chip>
              <v-chip label
                      small
                      v-if="timeSlot.oddWeeks">
                {{ $t('oddWeeks') }}
              </v-chip>
            </v-col>
            <v-col>
              <template v-if="timeSlot.comment != null && timeSlot.comment.length !== 0">
                <strong>{{ timeSlot.comment }}</strong><br>
              </template>
              {{ timeSlot.streetAddress }}<br>
              {{ timeSlot.postalCode }} {{ timeSlot.city }}<br>
              {{ timeSlot.country }}<br>
              <v-chip color="deep-orange"
                      dark
                      label
                      small
                      v-if="timeSlot.latitude == null && timeSlot.longitude == null">
                {{ $t('pending') }}
              </v-chip>
            </v-col>
            <v-col cols="auto">
              <v-btn class="mr-2"
                     icon
                     v-bind:color="timeSlot.disabled ? 'deep-orange' : 'accent'"
                     v-on:click="toggleTimeSlotDisabled(timeSlot.id)">
                <v-icon v-if="timeSlot.disabled">mdi-eye-off</v-icon>
                <v-icon v-else>mdi-eye</v-icon>
              </v-btn>
              <v-btn class="mr-2"
                     color="accent"
                     icon
                     v-on:click="editTimeSlot(timeSlot.id)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn color="deep-orange"
                     icon
                     v-on:click="deleteTimeSlot(timeSlot.id)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider v-bind:key="`${dayOfWeekGroup.dayOfWeek}-divider`" />
      </template>
      <v-btn block
             color="accent"
             text
             tile
             v-on:click="addTimeSlot()">
        {{ $t('addTimeSlot') }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import FoodTruckHoursDialog from '@/components/FoodTruckHoursDialog'

  export default {
    components: {
      FoodTruckHoursDialog
    },
    computed: {
      timeSlotGroups() {
        return this.foodTruck.timeSlots.reduce((result, current) => {
          const index = result.findIndex(g => g.dayOfWeek === current.dayOfWeek)

          if (index !== -1) {
            result[index].timeSlots.push(current)

            result[index].timeSlots = result[index].timeSlots.sort((a, b) => a.from.localeCompare(b.from))
          } else {
            result.push({
              dayOfWeek: current.dayOfWeek,
              timeSlots: [ current ]
            })
          }

          return result
        }, []).sort((a, b) => a.dayOfWeek - b.dayOfWeek)
      },
      ...mapGetters([ 'daysOfWeekLabel' ])
    },
    data: () => ({
      foodTruckHoursDialog: false
    }),
    name: 'FoodTruckHoursCard',
    methods: {
      addTimeSlot() {
        this.$refs.foodTruckHoursDialog.open(null, r => {
          if (r != null) {
            this.deleteTimeSlot(r.id)
            this.foodTruck.timeSlots.push(r)
          }
        })
      },
      deleteTimeSlot(id) {
        const index = this.foodTruck.timeSlots.findIndex(t => t.id === id)

        if (index !== -1) {
          this.foodTruck.timeSlots.splice(index, 1)
        }
      },
      editTimeSlot(id) {
        const timeSlot = this.foodTruck.timeSlots.find(t => t.id === id)

        this.$refs.foodTruckHoursDialog.open(timeSlot, r => {
          if (r != null) {
            this.deleteTimeSlot(r.id)
            this.foodTruck.timeSlots.push(r)
          }
        })
      },
      toggleTimeSlotDisabled(id) {
        const timeSlot = this.foodTruck.timeSlots.find(t => t.id === id)

        timeSlot.disabled = !timeSlot.disabled

        this.deleteTimeSlot(id)
        this.foodTruck.timeSlots.push(timeSlot)
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    props: {
      foodTruck: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "addTimeSlot": "Zeit hinzufügen",
      "evenWeeks": "Gerade Wochen",
      "from": "von",
      "hours": "Öffnungszeiten",
      "oddWeeks": "Ungerade Wochen",
      "pending": "Ausstehend",
      "title": "Titel",
      "to": "bis"
    },
    "en": {
      "addTimeSlot": "Add time slot",
      "evenWeeks": "Even weeks",
      "from": "from",
      "hours": "Hours",
      "oddWeeks": "Odd weeks",
      "pending": "Pending",
      "title": "Title",
      "to": "to"
    }
  }
</i18n>