<template>
  <div>
    <v-card class="mb-4"
            hover
            v-bind:rounded="$vuetify.breakpoint.mdAndDown ? null : 'lg'"
            v-bind:tile="$vuetify.breakpoint.mdAndDown"
            v-bind:key="foodTruck.id"
            v-for="foodTruck in highlightedFoodTrucks"
            v-on:click="$emit('food-truck-selected', foodTruck.id )">
      <v-img class="align-end white--text"
             height="200px"
             gradient="to top, rgba(0, 0, 0, .6), rgba(0, 0, 0, .1)"
             v-bind:src="foodTruck.imageId != null ? attachmentUrl(foodTruck.imageId) : defaultImageUrl">
        <v-card-title>
          <v-avatar class="mr-4"
                    size="48"
                    v-if="foodTruck.logoId != null">
            <v-img v-bind:src="attachmentUrl(foodTruck.logoId)" />
          </v-avatar>
          <v-row dense>
            <v-col cols="12" md="8">{{ foodTruck.title }}</v-col>
            <v-col class="text-md-right" cols="12" md="4">{{ foodTruck.regionName || foodTruck.city }}</v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="foodTruck.open">
          <v-chip small>{{ $t('openToday') }}</v-chip>
        </v-card-text>
        <v-card-text v-else>
          <v-chip dark
                  color="deep-orange"
                  small>
            {{ $t('closedToday') }}
          </v-chip>
        </v-card-text>
      </v-img>
    </v-card>
    <v-card v-bind:rounded="$vuetify.breakpoint.mdAndDown ? null : 'lg'"
            v-bind:tile="$vuetify.breakpoint.mdAndDown">
      <v-text-field flat
                    hide-details="auto"
                    prepend-inner-icon="mdi-magnify"
                    solo
                    v-bind:label="$t('search')"
                    v-model="search" />
      <v-divider />
      <v-list class="py-0">
        <template v-for="(group, index) of remainingFoodTruckGroups">
          <v-subheader class="font-weight-bold"
                       inset
                       v-bind:key="`group-${index}-subheader`">
            {{ regionGroupTitle(group.regionGroup) }}
          </v-subheader>

          <template v-for="(foodTruck, index) in group.foodTrucks">
            <v-list-item exact
                         v-bind:key="`${foodTruck.id}-list_item`"
                         v-on:click="$emit('food-truck-selected', foodTruck.id )">
              <v-list-item-avatar v-bind:color="foodTruck.logoId == null ? 'primary' : null">
                <v-img v-bind:src="attachmentUrl(foodTruck.logoId)"
                       v-if="foodTruck.logoId != null" />
                <v-icon color="white"
                        dark
                        v-else>
                  mdi-truck
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ foodTruck.title }}</v-list-item-title>
                <v-list-item-subtitle class="light-green--text text--darken-1" v-if="foodTruck.open">{{ $t('openToday') }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="foodTruck.specialties">{{ foodTruck.specialties }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{ categoryTitle(foodTruck.categoryId) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset
                       v-bind:key="`${foodTruck.id}-divider`"
                       v-if="index !== group.foodTrucks.length - 1" />
          </template>

          <v-divider v-bind:key="`group-${index}-divider`"
                     v-if="index !== remainingFoodTruckGroups.length - 1" />
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment'

  import { mapGetters } from 'vuex'

  export default {
    computed: {
      remainingFoodTruckGroups() {
        return this.remainingFoodTrucks.reduce((result, current) => {
          const index = result.findIndex(g => g.regionGroup === current.regionGroup)

          if (index !== -1) {
            result[index].foodTrucks.push(current)
          } else {
            result.push({
              regionGroup: current.regionGroup,
              foodTrucks: [ current ]
            })
          }

          return result
        }, []).sort((a, b) => a.regionGroup?.localeCompare(b.regionGroup) ?? 1)
      },
      remainingFoodTrucks() {
        return this.enrichedFoodTrucks.filter(e => !e.highlight)
      },
      enrichedFoodTrucks() {
        let filteredFoodTrucks = this.foodTrucks

        if (this.search != null && this.search.length !== 0) {
          filteredFoodTrucks = filteredFoodTrucks.filter(f => f.title.toLowerCase().includes(this.search.toLowerCase()))
        }

        return filteredFoodTrucks.map(f => {
          const dayOfWeek = moment().isoWeekday()
          const week = moment().isoWeek()
          const isEvenWeek = week % 2 === 0
          const isOddWeek = week % 2 === 1

          return {
            ...f,
            open: f.timeSlots.some(t => !t.disabled && t.dayOfWeek === dayOfWeek && (t.evenWeeks && isEvenWeek || t.oddWeeks && isOddWeek))
          }
        }).sort((a, b) => b.open - a.open)
      },
      highlightedFoodTrucks() {
        return this.enrichedFoodTrucks.filter(e => e.highlight)
      },
      ...mapGetters([
        'attachmentUrl',
        'categoryTitle'
      ])
    },
    data: () => ({
      search: null
    }),
    methods: {
      regionGroupTitle(regionGroup) {
        if (regionGroup == null || regionGroup.length === 0) {
          return this.$t('other')
        }

        return regionGroup
      }
    },
    name: 'FoodTruckListCard',
    props: {
      foodTrucks: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "closedToday": "Heute geschlossen",
      "openToday": "Heute geöffnet",
      "other": "Sonstige",
      "search": "Suche"
    },
    "en": {
      "closedToday": "Closed today",
      "openToday": "Open today",
      "other": "Other",
      "search": "Search"
    }
  }
</i18n>