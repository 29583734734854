<template>
  <div>
    <v-menu min-width="auto"
            ref="menu"
            offset-y
            transition="scale-transition"
            v-bind:close-on-content-click="false"
            v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field filled
                      hide-details="auto"
                      readonly
                      v-bind="attrs"
                      v-bind:label="label"
                      v-bind:rules="rules"
                      v-bind:value="value"
                      v-on="on">
            <v-icon left
                    slot="append"
                    v-bind:color="value">
              mdi-circle
            </v-icon>
        </v-text-field>
      </template>
      <v-color-picker dot-size="25"
                      swatches-max-height="200"
                      v-bind:value="value"
                      v-on:input="$emit('input', $event)" />
    </v-menu>
  </div>
</template>

<script>
  export default {
    data: () => ({
      menu: false
    }),
    name: 'ColorField',
    props: {
      label: String,
      prependInnerIcon: String,
      rules: Array,
      value: String
    }
  }
</script>