<template>
  <div v-if="currentFoodTruck != null">
    <confirm-dialog ref="confirmDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisFoodTruckIrrevocably')"
                    v-bind:title="$t('deleteFoodTruck')"
                    v-model="confirmDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="currentFoodTruck.title">
      <header-button icon="mdi-chevron-left"
                     left
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'administration-food-trucks' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateCurrentFoodTruck" />
      <v-menu slot="menu"
              v-if="hasRole('FoodTruckFinderAdministrator')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item v-on:click="deleteCurrentFoodTruck">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-container>
      <v-row>
        <v-col cols="auto">
          <v-btn color="secondary"
                 depressed
                 large
                 target="_blank"
                 v-bind:href="`${settings.apiUrl}/foodtruck/${id}/portfolio.pdf`">
            <v-icon left>
              mdi-file-pdf-box
            </v-icon>
            {{ $t('portfolio') }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="secondary"
                 depressed
                 large
                 target="_blank"
                 v-bind:href="`${settings.apiUrl}/foodtruck/${id}/schedule.pdf`">
            <v-icon left>
              mdi-file-pdf-box
            </v-icon>
            {{ $t('schedule') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               lg="6">
          <food-truck-details-card class="mb-8"
                                   ref="foodTruckDetailsCard"
                                   v-bind:food-truck="currentFoodTruck" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <food-truck-hours-card v-bind:food-truck="currentFoodTruck" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import settings from '@/settings'

  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'
  import FoodTruckDetailsCard from '@/components/FoodTruckDetailsCard'
  import FoodTruckHoursCard from '@/components/FoodTruckHoursCard'

  export default {
    components: {
      ConfirmDialog,
      HeaderButton,
      FoodTruckDetailsCard,
      FoodTruckHoursCard,
      TopHeader
    },
    computed: {
      settings() {
        return settings
      },
      ...mapGetters({
        foodTruck: 'foodTruck',
        hasRole: 'auth/hasRole'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      confirmDialog: false,
      currentFoodTruck: null,
      id: null,
      validationSnackbar: false
    }),
    methods: {
      async get(id) {
        this.id = id

        await this.loadCategories()
        await this.loadFoodTrucks()

        this.currentFoodTruck = Object.assign({}, this.foodTruck(id))
      },
      async deleteCurrentFoodTruck() {
        await this.$refs.confirmDialog.open(async r => {
          if (!r) {
            return
          }

          await this.deleteFoodTruck({ id: this.id })

          this.$router.push({ name: 'administration-food-trucks' })
        })
      },
      async updateCurrentFoodTruck() {
        if (!this.$refs.foodTruckDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.updateFoodTruck({ foodTruck: this.currentFoodTruck })

        this.$router.push({ name: 'administration-food-trucks' })
      },
      ...mapActions([
        'deleteFoodTruck',
        'loadCategories',
        'loadFoodTrucks',
        'updateFoodTruck'
      ])
    },
    name: 'FoodTruck'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisFoodTruckIrrevocably": "Sind Sie sicher, dass Sie diesen Foodtruck unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteFoodTruck": "Foodtruck löschen",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern.",
      "save": "Speichern",
      "schedule": "Standorte",
      "portfolio": "Portfolio"
    },
    "en": {
      "areYouSureYouWantToDeleteThisFoodTruckIrrevocably": "Are you sure you want to delete this food truck irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteFoodTruck": "Delete food truck",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention.",
      "save": "Save",
      "schedule": "Schedule",
      "portfolio": "Portfolio"
    }
  }
</i18n>