<template>
  <div>
    <v-card>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form">
        <v-card-text>
          <v-text-field class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:label="$t('title')"
                        v-bind:rules="[ rules.required ]"
                        v-model="category.title" />

          <v-autocomplete class="mb-6"
                    filled
                    hide-details="auto"
                    v-bind:items="foodIcons"
                    v-bind:label="$t('icon')"
                    v-model="category.icon">
            <template v-slot:item="{ item }">
              <v-list-item-icon>
                <v-icon>{{ `mdi-${item}` }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>

          <color-field filled
                        hide-details="auto"
                        v-bind:label="$t('color')"
                        v-bind:rules="[ rules.required ]"
                        v-model="category.color" />
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ColorField from '@/components/common/ColorField'

  export default {
    components: {
      ColorField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        foodIcons: 'foodIcons'
      })
    },
    name: 'CategoryDetailsCard',
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    props: {
      category: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "color": "Farbe",
      "details": "Details",
      "icon": "Symbol",
      "title": "Titel"
    },
    "en": {
      "color": "Color",
      "details": "Details",
      "icon": "Icon",
      "title": "Title"
    }
  }
</i18n>