import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"hide-overlay":"","persistent":"","width":"320"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"secondary","dark":""}},[_c(VCardText,{staticClass:"pa-6"},[_vm._v(" "+_vm._s(_vm.$t('signingIn'))+" ")]),_c(VExpandTransition,[_c(VCardActions,{directives:[{name:"show",rawName:"v-show",value:(_vm.showCancel),expression:"showCancel"}]},[_c(VSpacer),_c(VBtn,{attrs:{"color":"deep-orange","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1),_c(VProgressLinear,{attrs:{"color":"primary","indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }