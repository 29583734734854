import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[(!_vm.chromeless)?_c(VNavigationDrawer,{attrs:{"app":"","clipped":"","color":"secondary","dark":"","mini-variant":this.$vuetify.breakpoint.lgAndUp},model:{value:(_vm.navigationDrawer),callback:function ($$v) {_vm.navigationDrawer=$$v},expression:"navigationDrawer"}},[_c('router-view',{attrs:{"name":"navigation"}})],1):_vm._e(),(!_vm.chromeless)?_c(VAppBar,{attrs:{"app":"","clipped-left":"","color":"secondary","dark":"","flat":""}},[(!this.$vuetify.breakpoint.lgAndUp)?_c(VAppBarNavIcon,{on:{"click":function($event){_vm.navigationDrawer = !_vm.navigationDrawer}}}):_vm._e(),_c(VToolbarTitle,[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Foodtrucks")]),_vm._v(" United ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"accent lighten-3","href":("mailto:" + _vm.contactEmail),"icon":_vm.$vuetify.breakpoint.smAndDown,"text":_vm.$vuetify.breakpoint.mdAndUp}},[_c(VIcon,[_vm._v("mdi-email")])],1),_c('locale-block',{attrs:{"locales":_vm.locales},on:{"set-locale":_vm.setLocale}}),_c('account-block',{attrs:{"display-name":_vm.displayName,"email":_vm.email,"hide-sign-in":true,"is-authenticated":_vm.isAuthenticated,"picture":_vm.picture},on:{"sign-in":_vm.signIn,"sign-out":_vm.signOut}},[(_vm.hasRole('FoodTruckFinderAdministrator') || _vm.hasRole('FoodTruckFinderEditor'))?[_c(VListItem,{attrs:{"to":{ name: 'administration-food-trucks' }}},[_c(VListItemAvatar),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('administration')))])],1)],1),_c(VDivider)]:_vm._e()],2)],1):_vm._e(),_c(VMain,[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }