<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="isPartner ? $t('newPartner') : $t('newFoodTruck')">
      <header-button icon="mdi-chevron-left"
                     left
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'administration-food-trucks' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-on:click="save" />
    </top-header>
    <v-container>
      <food-truck-details-card class="mb-8"
                               ref="foodTruckDetailsCard"
                               v-bind:food-truck="foodTruck" />

      <food-truck-hours-card v-bind:food-truck="foodTruck" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'
  import FoodTruckDetailsCard from '@/components/FoodTruckDetailsCard'
  import FoodTruckHoursCard from '@/components/FoodTruckHoursCard'

  export default {
    components: {
      HeaderButton,
      FoodTruckDetailsCard,
      FoodTruckHoursCard,
      TopHeader
    },
    computed: {
      isPartner() {
        return this.foodTruck?.partner
      }
    },
    async created() {
      const partner = this.$route.query.partner

      this.foodTruck.partner = partner === "true" || partner === true

      await this.loadCategories()
    },
    data: () => ({
      foodTruck: {
        partner: false,
        timeSlots: []
      },
      validationSnackbar: false
    }),
    methods: {
      async save() {
        if (!this.$refs.foodTruckDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.createFoodTruck({ foodTruck: this.foodTruck })

        if (this.isPartner) {
          this.$router.push({ name: 'administration-partners' })
        } else {
          this.$router.push({ name: 'administration-food-trucks' })
        }


      },
      ...mapActions([
        'createFoodTruck',
        'loadCategories'
      ])
    },
    name: 'FoodTruckAdd'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "back": "Zurück",
      "newFoodTruck": "Neuer Foodtruck",
      "newPartner": "Neuer Partner",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "add": "Add",
      "back": "Back",
      "newFoodTruck": "New food truck",
      "newPartner": "New partner",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>