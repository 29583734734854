<template>
  <div>
    <v-card>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('spots') }}</v-toolbar-title>
        <template v-slot:extension>
          <v-text-field flat
                        hide-details="auto"
                        prepend-icon="mdi-magnify"
                        solo
                        v-bind:label="$t('search')"
                        v-model="search" />
        </template>
      </v-toolbar>
      <v-list>
        <template v-for="(spot, index) in filteredSpots">
          <v-list-item exact
                       v-bind:key="`${spot.id}-list_item`"
                       v-bind:to="{ name: 'administration-spot', params: { id: spot.id } }">
            <v-list-item-avatar>
              <v-icon class="primary"
                      color="white"
                      dark>
                mdi-fire
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ spot.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset
                       v-bind:key="`${spot.id}-divider`"
                       v-if="index !== filteredSpots.length - 1" />
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      filteredSpots() {
        if (this.search == null || this.search.length === 0) {
          return this.spots
        }

        return this.spots.filter(s => s.title.toLowerCase().includes(this.search.toLowerCase()))
      }
    },
    data: () => ({
      search: null
    }),
    name: 'SpotAdministrationListCard',
    props: {
      spots: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "search": "Suchen…",
      "spots": "Spots"
    },
    "en": {
      "search": "Search…",
      "spots": "Spots"
    }
  }
</i18n>