<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('newSpot')">
      <header-button icon="mdi-chevron-left"
                     left
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'administration-spots' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-on:click="save" />
    </top-header>
    <v-container>
      <spot-details-card ref="spotDetailsCard"
                         v-bind:spot="spot" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'
  import SpotDetailsCard from '@/components/SpotDetailsCard'

  export default {
    components: {
      HeaderButton,
      SpotDetailsCard,
      TopHeader
    },
    data: () => ({
      spot: {},
      validationSnackbar: false
    }),
    methods: {
      save() {
        if (!this.$refs.spotDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.createSpot({ spot: this.spot })

        this.$router.push({ name: 'administration-spots' })
      },
      ...mapActions([ 'createSpot' ])
    },
    name: 'SpotAdd'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "back": "Zurück",
      "newSpot": "Neuer Spot",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "add": "Add",
      "back": "Back",
      "newSpot": "New spot",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>