import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.spots.length === 0)?[_c('div',{staticClass:"grey--text text-center ma-10"},[_vm._v(" "+_vm._s(_vm.$t('thereAreCurrentlyNoSpotsAvailable'))+" ")])]:_vm._e(),_c(VRow,_vm._l((_vm.spots),function(spot){return _c(VCol,{attrs:{"cols":"12","lg":"4","md":"6"}},[_c(VCard,{attrs:{"target":"_blank","rounded":_vm.$vuetify.breakpoint.mdAndDown ? null : 'lg',"tile":_vm.$vuetify.breakpoint.mdAndDown,"href":spot.link}},[_c(VToolbar,{attrs:{"dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(spot.title))])],1),_c(VCardText,{staticStyle:{"background-color":"#d8d8d8"}},[(spot.imageId != null)?_c(VImg,{attrs:{"src":_vm.attachmentUrl(spot.imageId)}}):_vm._e()],1)],1)],1)}),1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }