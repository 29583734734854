import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('details')))])],1),_c(VForm,{ref:"form"},[_c(VCardText,[_c(VTextField,{staticClass:"mb-6",attrs:{"filled":"","hide-details":"auto","label":_vm.$t('title'),"rules":[ _vm.rules.required ]},model:{value:(_vm.spot.title),callback:function ($$v) {_vm.$set(_vm.spot, "title", $$v)},expression:"spot.title"}}),_c(VTextField,{staticClass:"mb-6",attrs:{"filled":"","hide-details":"auto","label":_vm.$t('description'),"rules":[ _vm.rules.required ]},model:{value:(_vm.spot.description),callback:function ($$v) {_vm.$set(_vm.spot, "description", $$v)},expression:"spot.description"}}),_c(VTextField,{staticClass:"mb-6",attrs:{"filled":"","hide-details":"auto","label":_vm.$t('link')},model:{value:(_vm.spot.link),callback:function ($$v) {_vm.$set(_vm.spot, "link", $$v)},expression:"spot.link"}}),_c(VCheckbox,{staticClass:"mb-6",attrs:{"hide-details":"auto","label":_vm.$t('sendNotification')},model:{value:(_vm.sendNotification),callback:function ($$v) {_vm.sendNotification=$$v},expression:"sendNotification"}}),(_vm.sendNotification)?_c(VTextarea,{staticClass:"mb-6",attrs:{"filled":"","hide-details":"auto","label":_vm.$t('notificationText')},model:{value:(_vm.spot.notificationText),callback:function ($$v) {_vm.$set(_vm.spot, "notificationText", $$v)},expression:"spot.notificationText"}}):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('attachment-field',{staticClass:"mb-6",attrs:{"accept":"image/*","show-image":"","label":_vm.$t('image'),"max-size":10240},model:{value:(_vm.spot.imageId),callback:function ($$v) {_vm.$set(_vm.spot, "imageId", $$v)},expression:"spot.imageId"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }