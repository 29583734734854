import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"value":_vm.value,"width":"500"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline primary--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.cancelButtonLabel)+" ")]),_c(VBtn,{attrs:{"text":"","color":_vm.confirmButtonColor},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.confirmButtonLabel)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }