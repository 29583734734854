<template>
  <div>
    <v-card>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('categories') }}</v-toolbar-title>
        <template v-slot:extension>
          <v-text-field flat
                        hide-details="auto"
                        prepend-icon="mdi-magnify"
                        solo
                        v-bind:label="$t('search')"
                        v-model="search" />
        </template>
      </v-toolbar>
      <v-list>
        <template v-for="(category, index) in filteredCategories">
          <v-list-item exact
                       v-bind:key="`${category.id}-list_item`"
                       v-bind:to="{ name: 'administration-category', params: { id: category.id } }">
            <v-list-item-avatar v-bind:color="category.color">
              <v-icon color="white"
                      dark>
                {{ `mdi-${category.icon}` }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ category.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset
                       v-bind:key="`${category.id}-divider`"
                       v-if="index !== filteredCategories.length - 1" />
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      filteredCategories() {
        if (this.search == null || this.search.length === 0) {
          return this.categories
        }

        return this.categories.filter(c => c.title.toLowerCase().includes(this.search.toLowerCase()))
      }
    },
    data: () => ({
      search: null
    }),
    name: 'CategoryListCard',
    props: {
      categories: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "categories": "Kategorien",
      "search": "Suchen…"
    },
    "en": {
      "categories": "Categories",
      "search": "Search…"
    }
  }
</i18n>