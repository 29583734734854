<template>
  <div>
    <top-header v-bind:title="$t('categories')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('new')"
                     v-bind:to="{ name: 'administration-category-add' }" />
    </top-header>
    <v-container>
      <category-list-card v-bind:categories="categories" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'
  import CategoryListCard from '@/components/CategoryListCard'

  export default {
    components: {
      CategoryListCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters([ 'categories' ])
    },
    async created() {
      await this.loadCategories()
    },
    methods: {
      ...mapActions([ 'loadCategories' ])
    },
    name: 'Categories'
  }
</script>

<i18n>
  {
    "de": {
      "categories": "Kategorien",
      "new": "Neu"
    },
    "en": {
      "categories": "Categories",
      "new": "New"
    }
  }
</i18n>