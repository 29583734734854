<template>
  <div>
    <v-container v-bind:class="{ 'pa-0': !this.$vuetify.breakpoint.mdAndUp }">
      <spot-list-card v-bind:spots="spots" />
    </v-container>

    <v-btn bottom
           class="mb-4 mr-4"
           dark
           exact
           fab
           fixed
           right
           v-bind:to="{ name: 'home' }">
      <v-icon>mdi-map</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import SpotListCard from '@/components/SpotListCard'

  export default {
    components: {
      SpotListCard
    },
    computed: {
      ...mapGetters([
        'spots'
      ])
    },
    async created() {
      await this.loadSpots()
    },
    data: () => ({
    }),
    methods: {
      ...mapActions([
        'loadSpots'
      ])
    },
    name: 'SpotsOverview'
  }
</script>