import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTextField,{attrs:{"flat":"","hide-details":"auto","prepend-icon":"mdi-magnify","solo":"","label":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-sort")])],1)]}}])},[_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedSortingType),callback:function ($$v) {_vm.selectedSortingType=$$v},expression:"selectedSortingType"}},_vm._l((_vm.sortingTypes),function(sortingType){return _c(VListItem,{key:sortingType.value,attrs:{"value":sortingType.value}},[_c(VListItemTitle,[_vm._v(_vm._s(sortingType.label))])],1)}),1)],1)],1)],1),_c(VList,[_vm._l((_vm.filteredFoodTrucks),function(foodTruck,index){return [_c(VListItem,{key:((foodTruck.id) + "-list_item"),attrs:{"exact":"","to":{ name: 'administration-food-truck', params: { id: foodTruck.id } }}},[_c(VListItemAvatar,{attrs:{"color":foodTruck.logoId == null ? 'primary' : null}},[(foodTruck.logoId != null)?_c(VImg,{attrs:{"src":_vm.attachmentUrl(foodTruck.logoId)}}):_c(VIcon,{attrs:{"color":"white","dark":""}},[_vm._v(" mdi-truck ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(foodTruck.title)+" ("+_vm._s(foodTruck.id)+")")])],1)],1),(index !== _vm.filteredFoodTrucks.length - 1)?_c(VDivider,{key:((foodTruck.id) + "-divider"),attrs:{"inset":""}}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }