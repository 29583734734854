<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('newCategory')">
      <header-button icon="mdi-chevron-left"
                     left
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'administration-categories' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-on:click="save" />
    </top-header>
    <v-container>
      <category-details-card ref="categoryDetailsCard"
                             v-bind:category="category" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import CategoryDetailsCard from '@/components/CategoryDetailsCard'
  import HeaderButton from '@/components/common/HeaderButton'
  import TopHeader from '@/components/common/TopHeader'

  export default {
    components: {
      CategoryDetailsCard,
      HeaderButton,
      TopHeader
    },
    data: () => ({
      category: {
        color: '#000000'
      },
      validationSnackbar: false
    }),
    methods: {
      save() {
        if (!this.$refs.categoryDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.createCategory({ category: this.category })

        this.$router.push({ name: 'administration-categories' })
      },
      ...mapActions([ 'createCategory' ])
    },
    name: 'CategoryAdd'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "back": "Zurück",
      "newCategory": "Neue Kategorie",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "add": "Add",
      "back": "Back",
      "newCategory": "New category",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>