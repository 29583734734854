import moment from 'moment'
import Vue from 'vue'

import i18n from './plugins/i18n'

Vue.filter('formatDate', (value, format, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return moment(value).locale(i18n.locale).format(format ?? 'LL')
})