import Vue from 'vue'

import App from './App.vue'
import './filters'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import settings from './settings'
import store from './store'

mapkit.init({
  authorizationCallback: done => {
    done(settings.mapKitJsToken)
  }
})

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
  vuetify
}).$mount('#app')